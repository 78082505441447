//
// Load data for Ladder Predictor
//

const TEST_MODE = false;

const DOMAIN = (TEST_MODE ? 'squiggle.eve' : 'squiggle.com.au');
const LOGO_URL = 'https://' + DOMAIN;
const API = 'https://api.' + DOMAIN;

export const THIS_YEAR = 2024;

async function dataImport(options) {

	const data = await loadAPIData(options);

	for (let key in data) {
		if (typeof data[key] === 'object') {
			data[key] = parseData(data[key]);
		}
	}

	//
	// Generate default setup for 'results', so each team has 0 wins, 0 losses, etc.
	//
	//
	const results = { };
	for (let id in data.teams) {
		results[id] = { wins: 0, losses: 0, draws: 0, ptsFor: 0, ptsAgt: 0 };
	}

	//
	// Build current ladder
	//
	data.finals = { }

	for (let id in data.complete) {
		const g = data.complete[id];
		if (g.is_final) {
			data.finals[g.id] = g;
			delete data.games[g.id];
		} else {
			results[g.hteamid].ptsFor += g.hscore;
			results[g.hteamid].ptsAgt += g.ascore;
			results[g.ateamid].ptsFor += g.ascore;
			results[g.ateamid].ptsAgt += g.hscore;
			if (g.hscore > g.ascore) {
				results[g.hteamid].wins++;
				results[g.ateamid].losses++;
			} else if (g.hscore < g.ascore) {
				results[g.ateamid].wins++;
				results[g.hteamid].losses++;
			} else {
				results[g.ateamid].draws++;
				results[g.hteamid].draws++;
			}
		}
	}

	data.results = results;

	if (data.games) {
		for (let game of Object.values(data.games)) {
			if (game.is_final) {
				data.finals[game.id] = game;
				delete data.games[game.id];
			}
		}

		//
		// Generate 'fixture': an array of gameIds in chronological order.
		//

		const fixture = Object.keys(data.games).map((id) => Number(id)).sort((a, b) => {
			return new Date(data.games[a].date).getTime() - new Date(data.games[b].date).getTime()
		});
		data.fixture = fixture;
	}

	// delete data.complete;

	//
	// Signal test mode, so I don't accidentally commit the app to production with
	// test mode enabled.
	//
	data.testMode = TEST_MODE;

	return data;
}

//
// Receive data like: [ { id: 2, name: 'Richmond', ... }, ... ]
// and transform so that the id is the hash index,
// e.g.: data = { 2: { id: 2, name: 'Richmond', ... } }, ;
//
function parseData(data) {
	let d = { };
	for (let dx of data) {

		// Hacky for team logos
		if (dx.logo) {
			dx.logo = LOGO_URL + dx.logo.replace(/\.jpg$/, '.png');
		}

		// Hacky to rename GWS
		if (dx.name === 'Greater Western Sydney') {
			dx.name = 'GWS';
		}

		d[dx.id || dx.gameid] = dx;
	}
	return d;
}

async function loadAPIData(options) {

	const { allowPastTips, fixtureYear } = options

	const year = fixtureYear || THIS_YEAR

	// All teams
	const fetchTeams = fetch( API + `?q=teams&year=${year}` )
		.then((response) => response.json())
		.catch(err => console.error("Failed to fetch teams", err));

	// All games that require tips
	const fetchGames = fetch( API + `?q=games&year=${year}${allowPastTips ? '' : '&complete=!100'}` )
		.then((response) => response.json())
		.catch(err => console.error("Failed to fetch games", err));

	// Squiggle s10 Tips
	const fetchAI = fetch( API + `?q=tips;year=${year};source=21`)
		.then((response) => response.json())
		.catch(err => console.error("Failed to fetch tips", err));

	// Complete games to build initial ladder
	let c
	if (allowPastTips) {
		c = { games: [ ] }
	} else {
		const fetchComplete = fetch( API + `?q=games;year=${year};complete=100`)
			.then((response) => response.json())
			.catch(err => console.error("Failed to fetch completed games", err));

		c =  await fetchComplete;
	}

	const t = await fetchTeams;
	const g = await fetchGames;
	const ai = await fetchAI;

	return {
		year,
		loadTime: new Date().getTime(),
		teams: t && t.teams,
		games: g && g.games,
		ai: ai && ai.tips,
		complete: c && c.games,
	}
}

export default dataImport;
