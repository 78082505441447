//
// AFL Ladder Predictor
//
// by Max Barry
// May 2018
//
//
// To develop locally: "npm start"
//
// DATA STRUCTURE
// ~~~~~~~~~~~~~~
// 'teams': { 14: { name: 'Richmond', id: 14, ... }, ... }
//
// 'games': { 459: { id: 459, hteamid: 1, venue: 'M.C.G.', ... }, ... }
//
// 'fixture': [ 459, 460, 461, 466, 462, ... ]
//
// 'ai': { 459: { gameid: 459, source: 21, hconfidence: 58.12, ... }, ... }
//

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import ReactCursorPosition from 'react-cursor-position';

import dataImport from './DataImport';
import AutoTip, { doAutoTip, ptsToScores, tipToRel } from './AutoTip';
import HelpText from './HelpText';
import Ladder from './Ladder';
import Fixture from './Fixture';
import GameScore from './GameScore';
import ModalCloseBox from './ModalCloseBox';
import TeamLogo from './TeamLogo';
import ManualEntry from './ManualEntry';

import pkgInfo from '../package.json'

import logo from './logo.png';
import normaldist from './normaldist.svg';
import rateMyLadder from './ratemyladder.png';

import "typeface-libre-franklin/index.css";
import "react-sweet-progress/lib/style.css";

import './squiggle-app.css';

//
// Google Analytics
//
import ReactGA from 'react-ga';
ReactGA.initialize('UA-3291802-7');
ReactGA.pageview('/predictor');

const RANGE = 130

class App extends Component {

	constructor(props) {

		super(props);

		this.state = {
			isChanging: false,
			isAutoTipping: false,
			showFixture: false,
			showFixtureTeam: 0,
			showReset: false,
			showManual: false,
			lastClick: new Date(),
			allowPastTips: false,
			fixtureYear: undefined,
			isApp: false,
		};

		this.loadData = this.loadData.bind(this);
		this.nextGame = this.nextGame.bind(this);
		this.uClicked = this.uClicked.bind(this);
		this.uManual = this.uManual.bind(this);
		this.toggleShowFixture = this.toggleShowFixture.bind(this);
		this.toggleShowReset = this.toggleShowReset.bind(this);
		this.switchToGame = this.switchToGame.bind(this);
		this.autoTip = doAutoTip.bind(this);
		this.resetTips = this.resetTips.bind(this);
		this.toggleTeamFilter = this.toggleTeamFilter.bind(this);
		this.toggleShowManual = this.toggleShowManual.bind(this);
		this.saveUserTips = this.saveUserTips.bind(this);
		this.setAllowPastTips = this.setAllowPastTips.bind(this);
		this.setFixtureYear = this.setFixtureYear.bind(this);

		ReactModal.setAppElement('#root');

		//
		// Special CSS for when viewed in the Squiggle App
		//
		if (window.navigator.userAgent.match(/^Squiggle App/)) {
			this.state.isApp = true;
		}
	}

	loadData() {
		const { allowPastTips, fixtureYear } = this.state

		const options = {
			allowPastTips,
			fixtureYear,
		}

		dataImport(options).then((data) => {

			console.log("DATA import", data)

			if (!data || !data.fixture || !data.games) {
				this.setState({
					loadError: true,
				})
				return
			}

			//
			// Create 'showGames'
			//
			data.showGames = data.fixture;

			// Tips
			const savedTips = this.loadUserTips(data.year);
			if (savedTips) {
				if (!savedTips.tips) {
					// Convert from old format
					savedTips.tips = savedTips;
				}
				data.tips = this.removeOldTips(savedTips.tips, data.games);
				if (savedTips.finals) {
					data.savedFinals = savedTips.finals;
				} else {
					data.savedFinals = { };
				}
			} else {
				data.tips = { };
				data.savedFinals = { };
			}

			data.lastClick = new Date();

			this.setState(data);
		});
	}

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps, prevState) {

		// Change game when we change the filter or click somewhere in the clickzone
		if (prevState.lastClick !== this.state.lastClick || prevState.showFixtureTeam !== this.state.showFixtureTeam) {
			this.nextGame();
		}
	}

	toggleShowFixture() {
		this.setState({ showFixture: !this.state.showFixture });
	}

	toggleShowManual(e) {
		if (e) {
			e.stopPropagation();
		}
		this.setState({ showManual: !this.state.showManual });
	}

	//
	// Toggle whether a team is being shown or not
	//
	toggleTeamFilter(teamId) {
		const newTeam = this.state.showFixtureTeam === teamId ? 0 : teamId;

		const showGames = this.state.fixture.filter((gameId) => !newTeam || this.state.games[gameId].hteamid === newTeam || this.state.games[gameId].ateamid === newTeam);

		this.setState({
			showFixtureTeam: newTeam,
			showGames: showGames,
		});
	}

	toggleShowReset() {
		this.setState({ showReset: !this.state.showReset });
	}

	//
	// Throw away tipped games.
	//
	resetTips(arg) {

		const newTips = { };

		const newState = {
			showReset: false,
			lastClick: new Date(),
		};

		if (arg === 'ai') {
			// Copy across non-AI tips
			for (let t of Object.values(this.state.tips)) {
				if (!t.aiTip) {
					newTips[t.gameId] = t;
				}
			}
		} else if (arg === 'actual') {
			for (let g of Object.values(this.state.games)) {
				if (g.hscore && g.ascore) {
					newTips[g.id] = {
						gameId: g.id,
						hPts: g.hscore,
						aPts: g.ascore,
						winner: g.winnerteamid,
					};
				}
			}
			newState.loadTime = Date.now()
			newState.savedFinals = null
		}

		newState.tips = newTips

		this.setState(newState);
	}

	//
	// Navigate to the next game.
	//
	nextGame() {

		const curGame = this.state.fixture.GameNumber;

		let nextGameNumber;

		let searchForUntipped = function(arr) {
			// Step 1. Search forward starting from current game, if we have one.
			let skip = (curGame === undefined ? false : true);
			for (let id of arr) {
				if (skip) {
					if (id === curGame) {
						skip = false;
					}
				} else {
					if (!this.state.tips[id]) {
						return id;
					}
				}
			}

			// Step 2: Search from beginning
			if (nextGameNumber === undefined) {
				for (let id of arr) {
					if (id === curGame) {
						break;
					} else {
						if (!this.state.tips[id]) {
							return id;
						}
					}
				}
			}

			return undefined;
		}

		searchForUntipped = searchForUntipped.bind(this);

		let nextGameId = searchForUntipped(this.state.showGames);

		if (nextGameId === undefined) {
			//
			// Finished! No untipped games.
			//
			nextGameNumber = null;
		} else {
			for (let i = 0; i < this.state.fixture.length; i++) {
				if (this.state.fixture[i] === nextGameId) {
					nextGameNumber = i;
					break;
				}
			}
		}

		this.setState({ gameNumber: nextGameNumber, isChanging: true },
			() => {
				setTimeout(() => this.setState({ isChanging: false }), 200)
			});
	}

	//
	// User has clicked to record a new tip.
	//
	uClicked(data) {
		const winner = data.iPts > 0 ? data.game.hteam.id : data.iPts < 0 ? data.game.ateam.id : 0;
		const scores = ptsToScores(data.iPts, true);

		const tip = {
			gameId: data.game.id,
			winner: winner,
			hPts: scores.hPts,
			aPts: scores.aPts,
		};

		this.setTip(tip);
	}

	//
	// User manually entered a tip.
	//
	uManual(data) {
		const winner = (data.hPts > data.aPts ? data.game.hteam.id : data.hPts < data.aPts ? data.game.ateam.id : 0);

		const tip = {
			gameId: data.game.id,
			winner: winner,
			hPts: data.hPts,
			aPts: data.aPts,
		};

		this.setTip(tip);
	}

	setTip(tip) {
		const tips = Object.assign( { }, this.state.tips);
		tips[tip.gameId] = tip;

		this.setState( {
			tips: tips,
			lastClick: new Date(),
		});
	}

	//
	// Navigate to a specific game, even if it's already been tipped.
	//
	switchToGame(gameId) {
		for (let i = 0; i < this.state.fixture.length; i++) {
			if (gameId === this.state.fixture[i]) {
				this.setState( { gameNumber: i});
				break;
			}
		}
	}

	storageKey() {
		return 'squiggle-ladder-predictor-tips';
	}

	//
	// Load any tips from localstorage
	//
	loadUserTips(year) {
		const baseKey = this.storageKey();

		const keys = [ baseKey + `-${year}` ];

		//
		// If it's the year 2020 and we don't seem to have any stored
		// tips under the new format, check if we have something under
		// the old format, for backwards compatibility.
		//
		if (year === 2020) {
			keys.push(baseKey);
		}

		for (const key of keys) {
			if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
				const savedTips = localStorage.getItem(key);
				if (savedTips) {
					return JSON.parse(savedTips);
				}
			}
		}
	}

	//
	// Remove any tips for games that have occurred in real life.
	//
	removeOldTips(tips, games) {
		for (let gameId in tips) {
			if (!games[gameId]) {
				delete tips[gameId];
			}
		}
		return tips;
	}

	//
	// Save tips to localstorage
	//
	saveUserTips(finals) {
		const { year } = this.state

		let key = this.storageKey();
		if (year) {
			key += `-${year}`;
		}

		const data = {
			tips: this.state.tips,
			finals: finals,
		};
		localStorage.setItem(key, JSON.stringify(data));
	}

	//
	// Create a neat data structure on the current displayed game.
	//
	thisGame() {
		if (this.state.fixture) {
			const gameId = this.state.fixture[this.state.gameNumber];
			if (gameId !== undefined) {
				const thisGame = Object.assign({}, this.state.games[gameId]);
				thisGame.hteam = this.state.teams[thisGame.hteamid];
				thisGame.ateam = this.state.teams[thisGame.ateamid];
				thisGame.ai = this.state.ai[thisGame.id];
				return thisGame;
			}
			return null;
		}
		return undefined;
	}

	setAllowPastTips(value) {
		this.setState({
			allowPastTips: value,
		}, this.loadData)
	}

	setFixtureYear(value) {
		this.setState({
			fixtureYear: value,
		}, this.loadData)
	}

	render() {
		const thisGame = this.thisGame();
		const thisTip = thisGame && this.state.tips[thisGame.id];
		const { version } = pkgInfo

		return (
			<div className={"App " + (this.state.testMode ? 'test-mode ' : '') + (this.state.isApp ? 'isApp' : '')}>
				<header className="App-header">
					<a href="https://squiggle.com.au/" className="App-logo"><img src={logo} alt="Squiggle" /></a>
					<div className="App-header-text">
						<HelpText />
						<div className="title">Ladder Predictor</div>
					</div>
				</header>

				<Game
					game={thisGame}
					tip={thisTip}
					onClick={this.uClicked}
					uManual={this.uManual}
					isChanging={this.state.isChanging}
					showManual={this.state.showManual}
					toggleShowManual={this.toggleShowManual}
					loadError={this.state.loadError} />

				<StatusBar
					game={thisGame}
					games={this.state.games}
					showGames={this.state.showGames}
					teams={this.state.teams}
					tips={this.state.tips}
					gameNumber={this.state.gameNumber}
					fixture={this.state.fixture}
					fixtureYear={this.state.fixtureYear}
					toggleShowFixture={this.toggleShowFixture}
					toggleShowReset={this.toggleShowReset}
					switchToGame={this.switchToGame}
					autoTip={this.autoTip}
					autoTipIsDisabled={this.state.ai && Object.keys(this.state.ai).length ? false : true}
					resetTips={this.resetTips} />

				<Ladder
					teams={this.state.teams}
					results={this.state.results}
					games={this.state.games}
					game={thisGame}
					tips={this.state.tips}
                    fixtureYear={this.state.fixtureYear}
					finals={this.state.finals}
					savedFinals={this.state.savedFinals}
					allowPastTips={this.state.allowPastTips}
					complete={this.state.complete}
					saveUserTips={this.saveUserTips}
					loadTime={this.state.loadTime} />

				<Fixture
					teams={this.state.teams}
					tips={this.state.tips}
					fixture={this.state.fixture}
					games={this.state.games}
					showGames={this.state.showGames}
					showFixtureTeam={this.state.showFixtureTeam}
					showFixture={this.state.showFixture}
					switchToGame={this.switchToGame}
					allowPastTips={this.state.allowPastTips}
					setAllowPastTips={this.setAllowPastTips}
					fixtureYear={this.state.fixtureYear}
					setFixtureYear={this.setFixtureYear}
					toggleShowFixture={this.toggleShowFixture}
					toggleTeamFilter={this.toggleTeamFilter} />

				<AutoTip
					isAutoTipping={this.state.isAutoTipping}
					autoTipSims={this.state.autoTipSims}
					autoTipProgress={this.state.autoTipProgress} />

				<Reset
					showReset={this.state.showReset}
					toggleShowReset={this.toggleShowReset}
					tips={this.state.tips}
					resetTips={this.resetTips}
					allowPastTips={this.state.allowPastTips}
				/>

				<footer id="page-footer">
					<p className="maxbarry-link">
						v{version} by
						{' '}
						<a href="https://maxbarry.com" target="_new">
							Max Barry
						</a>
					</p>
					<a className="sbox rate-my-ladder-link" href="https://squiggle.com.au/rate-my-ladder/" target="_new">
						<img src={rateMyLadder} alt="" />
						<div>
							<div className="rml-title">RATE MY LADDER</div>
							<div className="explanatory-text">
								Made a preseason prediction?
								<br />
								Score it for accuracy here.
							</div>
						</div>
					</a>
				</footer>

			</div>
		);
	}
}

App.propTypes = {
	gameNumber: PropTypes.number,
	games: PropTypes.object,
	teams: PropTypes.object,
};

function StatusBar(props) {

	if (!props.fixture) {
		return null;
	}

	//
	// Not sure what I was doing here -- I think it needs to prevent
	// people from changing results after the Home & Away season. But it
	// was also making the status bar vanish at the start of 2019 when
	// people finished tipping, leaving them unable to adjust their results.
	//
	// if (Object.keys(props.games).length === Object.keys(props.tips).length) {
	//		return null;
	// }

	const curGameId = props.fixture[props.gameNumber];
	let prevGameId;
	for (let i = props.showGames.length - 1; i > 0; i--) {
		if (props.showGames[i] === curGameId) {
			prevGameId = props.showGames[i-1];
		}
	}

	const lastTip = props.tips[prevGameId];

	let html;

	if (lastTip === undefined) {
		html = null;
	} else {
		const game = props.games[lastTip.gameId];
		const hteam = props.teams[game.hteamid];
		const ateam = props.teams[game.ateamid];

		html = (
			<GameScore
				hteam={hteam}
				ateam={ateam}
				hscore={lastTip.hPts}
				ascore={lastTip.aPts}
				winner={lastTip.winner}
			/>
		);
	}

	const gamesTipped = Object.keys(props.tips).length;
	const gamesTotal = props.fixture.length;
	const gamesShown = props.showGames.length;
	const asterisk = (gamesTotal === gamesShown ? '' : '*');
	const fixtureYear = props.fixtureYear

	return (
		<section id="status-bar">
			<section id="last-tip" className="sbox" onClick={() => { lastTip && props.switchToGame(lastTip.gameId) }} >
				<header>
					Prev Game
				</header>

				{html}

			</section>

			<div id="status-bar-commands">
				<button id="ai-predict" className={"sbox command" + (props.autoTipIsDisabled ? ' disabled' : '')} onClick={props.autoTip} disabled={props.autoTipIsDisabled}>
					AutoTip
				</button>

				<button id="reset-tips" className="sbox command" onClick={props.toggleShowReset}>
					Reset
				</button>

			</div>

			<section id="games-remaining" className="sbox" onClick={props.toggleShowFixture}>
				<header>
					{fixtureYear ? `${fixtureYear} ` : ''}
					Fixture
				</header>
				<div className="games-remaining">
					<span className="num-games-remaining">{gamesTipped}</span> / {gamesShown}{asterisk} games tipped
				</div>
			</section>
		</section>
	);
}

StatusBar.propTypes = {
	game: PropTypes.object,
	tips: PropTypes.object,
	games: PropTypes.object,
	teams: PropTypes.object,
	fixture: PropTypes.array,
	showGames: PropTypes.array,
	gameNumber: PropTypes.number,
	toggleShowFixture: PropTypes.func,
	toggleShowReset: PropTypes.func,
	autoTip: PropTypes.func,
	autoTipIsDisabled: PropTypes.bool,
	fixtureYear: PropTypes.any,
	resetTips: PropTypes.func,
	switchToGame: PropTypes.func,
};

class Game extends Component {
	render() {
		return (
			<section className="Game">

				<GameHeader game={this.props.game} loadError={this.props.loadError} />

				<ReactCursorPosition>
					<ClickZone
						game={this.props.game}
						tip={this.props.tip}
						onClick={this.props.onClick}
						uManual={this.props.uManual}
						isChanging={this.props.isChanging}
						toggleShowManual={this.props.toggleShowManual}
					/>
				</ReactCursorPosition>

				<ManualEntry
					game={this.props.game}
					tip={this.props.tip}
					showManual={this.props.showManual}
					toggleShowManual={this.props.toggleShowManual}
					uManual={this.props.uManual}
				/>

		</section>
		);
	}
}

Game.propTypes = {
	game: PropTypes.object,
	tip: PropTypes.object,
	onClick: PropTypes.func,
	uManual: PropTypes.func,
	isChanging: PropTypes.bool,
	showManual: PropTypes.bool,
	toggleShowManual: PropTypes.func,
	loadError: PropTypes.any,
};

class GameHeader extends Component {
	render() {

		if (this.props.game === undefined) {
			if (this.props.loadError) {
				return (
					<p id="loading-indicator">Load Error: Could not fetch requested data from Squiggle API</p>
				)
			}
			return (
				<p id="loading-indicator">Loading...</p>
			);
		} else if (this.props.game === null) {
			return null;
		} else {
			return (
				<header id="game-header">
					<div id="game-header-date">
						<time dateTime={this.props.game.date}>{this.props.game.roundname || 'Round ' + this.props.game.round}, {this.props.game.year}</time>
					</div>

					<div id="game-header-teams">
						<span id="t1name">{this.props.game.hteam.name}</span> v <span id="t2name">{this.props.game.ateam.name}</span>
					</div>

					<div id="game-header-venue">{this.props.game.venue}</div>
				</header>
			);
		}
	}
}

GameHeader.propTypes = {
	game: PropTypes.object,
	loadError: PropTypes.any,
};

class ClickZone extends Component {

	constructor(props) {
		super(props);

		this.state = {
			rel: 0,
			pts: 0,
			iPts: 0,
			hide: false,
		};
		this.myClicky = this.myClicky.bind(this);
	}

	updateRel() {
		if (this.state.hide)
			return

		const x = this.props.position.x;
		const tot = this.props.elementDimensions.width;
		const rel = x / tot;
		const pts = (RANGE * (1 - rel)) - RANGE / 2;
		let iPts = Math.round(pts);

		this.setState( {
			rel: rel,
			iPts: iPts,
		});
	}

	componentDidMount() {
		this.updateRel();
	}

	UNSAFE_componentWillReceiveProps() {
		this.updateRel();
	}

	componentDidUpdate(prevProps) {
		if (this.state.hide) {
			if (prevProps.game && this.props.game && prevProps.game.id !== this.props.game.id) {
				// console.log('componentDidUpdate - game changed')
				this.setState({
					iPts: this.props.game.hscore - this.props.game.ascore,
				})
			}
		}
	}

	myClicky(e) {
		this.props.onClick({
			e: e,
			iPts: this.state.iPts,
			rel: this.state.rel,
			game: this.props.game,
		});
	}

	render() {
		if (!this.props.game) {
			return null;
		} else {
			const onMouseEnter = () => {
				if (this.props.game) {
					this.setState({
						hide: true,
						iPts: this.props.game.hscore - this.props.game.ascore,
					})
				}
			}

			const onMouseLeave = () => this.setState({ hide: false })

			return (
				<section id="clickzone" onClick={this.myClicky}>
					<TeamLogo team={this.props.game.hteam} home="home" />
					<TeamLogo team={this.props.game.ateam} home="away" />
					<NormalDist ai={this.props.game.ai} isChanging={this.props.isChanging} />
					<ManualButton toggleShowManual={this.props.toggleShowManual} />
					<ActualButton game={this.props.game} uManual={this.props.uManual} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
					{!this.state.hide && <Indicator x={this.props.position.x} />}
					<TipIndicator tip={this.props.tip} />
					<ResultIndicator game={this.props.game} />
					<div id="gamebackground">
						<TipText
							game={this.props.game}
							active={this.props.isActive}
							rel={this.state.rel}
							iPts={this.state.iPts}
						/>
						<GameBackground />
					</div>
				</section>
			);
		}
	}
}

ClickZone.propTypes = {
	game: PropTypes.object,
	onClick: PropTypes.func,
	position: PropTypes.object,
	tip: PropTypes.object,
	elementDimensions: PropTypes.object,
	isActive: PropTypes.bool,
	isChanging: PropTypes.bool,
	toggleShowManual: PropTypes.func,
	uManual: PropTypes.func,
};

function Indicator(props) {
	return (
		<span id="indicator-current" className="indicator" style={{left: props.x + 'px'}}>&#x25B2;</span>
	);
}

Indicator.propTypes = {
	x: PropTypes.number,
};

function TipIndicator(props) {
	if (!props.tip) {
		return null;
	}

	const tipRel = tipToRel(props.tip);

	return (
		<span id="indicator-past" className="indicator" style={{left: tipRel + '%'}}>&#x25B2;</span>
	);
}

TipIndicator.propTypes = {
	tip: PropTypes.object,
};

function ResultIndicator(props) {
	if (!props.game) {
		return null;
	}

	const { hscore, ascore } = props.game

	if (!hscore || !ascore) {
		return null;
	}

	const resultRel = tipToRel({
		hPts: hscore,
		aPts: ascore,
	});

	return (
		<span id="indicator-result" className="indicator" style={{left: Math.min(100, Math.max(0, resultRel)) + '%'}}>&#x25B2;</span>
	);
}

ResultIndicator.propTypes = {
	game: PropTypes.object,
};

function NormalDist(props) {
	const c = props.isChanging ? 'hidden' : '';

	if (props.ai === undefined) {
		return null;
	}

	return (
		<img id="normaldist" src={normaldist} className={c} style={{right: (props.ai.hconfidence - 90) + '%'}} alt="" />
	);
}

NormalDist.propTypes = {
	ai: PropTypes.object,
	isChanging: PropTypes.bool,
};

class TipText extends Component {
	constructor(props) {
		super(props);

		const clickLabel = ("ontouchstart" in document.documentElement) ? 'Tap' : 'Click';

		this.state = {
			clickLabel: clickLabel,
		}
	}

	render() {
		if (!this.props.active) {

			return (
				<div id="tiptext" className="tiptext-help">{this.state.clickLabel} to tip</div>
			);
		}
		if (!this.props.iPts) {
			return (
				<div id="tiptext" className="tiptext-draw">DRAW</div>
			)
		}
		const side = this.props.iPts > 0 ? 'home' : 'away';
		const t = this.props.iPts > 0 ? this.props.game.hteam : this.props.game.ateam;
		const pts = Math.abs(this.props.iPts);
		return (
			<div id="tiptext" className={'tiptext-' + side}>
				<div className="tiptext-logo-container">
					<img className="tinylogo" src={t.logo} alt="" />
				</div>
				by
				<b>
					{pts}
				</b>
				points
			</div>
		);
	}
}

TipText.propTypes = {
	rel: PropTypes.number,
	iPts: PropTypes.number,
	active: PropTypes.bool,
	game: PropTypes.object,
};

function GameBackground() {
	return (
		<div id="gbg">
			<div className="gbgsection">60</div>
			<div className="gbgsection">50</div>
			<div className="gbgsection">40</div>
			<div className="gbgsection">30</div>
			<div className="gbgsection">20</div>
			<div className="gbgsection">10</div>
			<div className="gbgsection">0</div>
			<div className="gbgsection">10</div>
			<div className="gbgsection">20</div>
			<div className="gbgsection">30</div>
			<div className="gbgsection">40</div>
			<div className="gbgsection">50</div>
			<div className="gbgsection">60</div>
		</div>
	);
}

class Reset extends Component {

	render() {

		if (!this.props.showReset) {
			return null;
		}

		let numHumanTips = 0;
		let numAITips = 0;

		for (let t of Object.values(this.props.tips)) {
			if (t.aiTip) {
				numAITips++;
			} else {
				numHumanTips++;
			}
		}

		const buttons = [ ];

		if (numAITips) {
			buttons.push((
				<ResetButton key="ai" resetType="ai" onClick={this.props.resetTips}>
					Reset AutoTips ({numAITips})
				</ResetButton>
			));
		}

		if (numHumanTips || !numAITips) {
			buttons.push((
				<ResetButton key="all" resetType="all" onClick={this.props.resetTips}>
					Reset All ({numHumanTips + numAITips})
				</ResetButton>
			));
		}

		if (this.props.allowPastTips) {
			buttons.push((
				<ResetButton key="actual" resetType="actual" onClick={this.props.resetTips}>
					Reset to Actual
				</ResetButton>
			));
		}

		return (
			<ReactModal
				isOpen={this.props.showReset}
				overlayClassName="ModalOverlay"
				className="ModalContent ModalContentSmall"
				contentLabel="Reset"
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				onRequestClose={this.props.toggleShowReset}
			>
				<ModalCloseBox closeModal={this.props.toggleShowReset} />
				<section id="reset-modal">
					<h2>
						Reset
					</h2>
					<div id="reset-buttons">
						{buttons.reduce((prev, curr) => [prev, ' ', curr])}
					</div>
				</section>
			</ReactModal>
		);
	}
}

Reset.propTypes = {
	showReset: PropTypes.bool,
	tips: PropTypes.object,
	toggleShowReset: PropTypes.func,
	resetTips: PropTypes.func,
	allowPastTips: PropTypes.bool,
};


class ResetButton extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		return this.props.onClick(this.props.resetType);
	}

	render() {
		return (
			<button
				className="sbox command"
				onClick={this.onClick}
			>
				{this.props.children}
			</button>
		);
	}
}

ResetButton.propTypes = {
	resetType: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node,
};

class ManualButton extends Component {
	render() {
		return (
			<button id="manual-button" className="sbox command" onClick={(e) => this.props.toggleShowManual(e)}>
				Manual
			</button>
		);
	}
}

ManualButton.propTypes = {
	toggleShowManual: PropTypes.func,
};

class ActualButton extends Component {
	render() {
		const { game, onMouseEnter, onMouseLeave } = this.props
		const { hscore, ascore } = game

		if (!hscore && !ascore)
			return null

		const winnerSide = hscore > ascore ? 'hteam' : hscore < ascore ? 'ateam' : null

		let content
		if (winnerSide) {
			const logo = winnerSide && game[winnerSide].logo
			content = (
				<React.Fragment>
					<img src={logo} alt="" className="tinylogo" />
					<span className="smalltext">
						by
					</span>
					<span>
						{Math.abs(hscore - ascore)}
					</span>
				</React.Fragment>
			)
		} else {
			content = 'DRAW'
		}

		const onClick = (e) => {
			e.stopPropagation()
			this.props.uManual({
				game,
				hPts: game.hscore,
				aPts: game.ascore,
			})
		}

		return (
			<button
				id="actual-button"
				className="sbox command"
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<span>
					Actual
				</span>
				<span className="actual-result">
					{content}
				</span>
			</button>
		);
	}
}

ActualButton.propTypes = {
	game: PropTypes.object,
	uManual: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};

export default App;
