import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import ModalCloseBox from './ModalCloseBox';
import GameScore from './GameScore';

class Fixture extends Component {

	render()
	{
		if (!this.props.showFixture) {
			return null;
		}

		return (
			<ReactModal
				isOpen={this.props.showFixture}
				overlayClassName="ModalOverlay"
				className="ModalContent"
				contentLabel="Tip History"
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				onRequestClose={this.props.toggleShowFixture}
			>
				<ModalCloseBox closeModal={this.props.toggleShowFixture} />
				<section id="tip-history">
					<h2>
						{this.props.fixtureYear ? `${this.props.fixtureYear} ` : ''}
						Fixture
					</h2>
					<fieldset className="team-filter-box">
						<legend>
							Filter
						</legend>
						<div className="team-filter">
							{Object.values(this.props.teams).map((t) =>
								<div
									key={t.id}
									className={"team-filter-logo " + (this.props.showFixtureTeam === t.id || this.props.showFixtureTeam === 0 ? 'selected' : '')}
									onClick={() => this.props.toggleTeamFilter(t.id)}
								>
									<img src={t.logo} className="tinylogo" alt={t.name} />
								</div>
							)}
						</div>
					</fieldset>
					<div id="fixture-key">
						Key:
						{
							this.props.allowPastTips && (
								<div className="actual-tip">
									Actual
								</div>
							)
						}
						<div className="untipped">
							Untipped
						</div>
						<div className="aiTip">
							AutoTipped
						</div>
					</div>
					<AllowTippingPastGames
						allowPastTips={this.props.allowPastTips}
						setAllowPastTips={this.props.setAllowPastTips}
						fixtureYear={this.props.fixtureYear}
						setFixtureYear={this.props.setFixtureYear}
					/>
					<div id="fixture-list-games">
						<div id="fixture-list-games-thead" className="fixture-row">
							<div className="fixture-cell">
								Round
							</div>
							<div className="fixture-cell">
							</div>
							<div className="fixture-cell">
							</div>
						</div>
						{this.props.showGames.map((gameId, index) =>
						<FixtureRow
							key={gameId}
							game={this.props.games[gameId]}
							hteam={this.props.teams[this.props.games[gameId].hteamid]}
							ateam={this.props.teams[this.props.games[gameId].ateamid]}
							tip={this.props.tips[gameId]}
							round={this.props.games[gameId].round}
							prevGameRound={index && this.props.games[this.props.showGames[index-1]].round}
							onClick={ () => { this.props.switchToGame(gameId); this.props.toggleShowFixture(); }}

						/>
						)}
					</div>
				</section>
				<ModalCloseBox closeModal={this.props.toggleShowFixture} />
			</ReactModal>
		);
	}
}

Fixture.propTypes = {
	teams: PropTypes.object,
	games: PropTypes.object,
	fixture: PropTypes.array,
	showGames: PropTypes.array,
	tips: PropTypes.object,
	switchToGame: PropTypes.func,
	toggleTeamFilter: PropTypes.func,
	showFixture: PropTypes.bool,
	toggleShowFixture: PropTypes.func,
	showFixtureTeam: PropTypes.number,
	allowPastTips: PropTypes.bool,
	setAllowPastTips: PropTypes.func,
	fixtureYear: PropTypes.any,
	setFixtureYear: PropTypes.func,
};

class FixtureRow extends Component {
	render() {
		const { game, tip } = this.props
		const round = game.round === this.props.prevGameRound ? null : game.round;
		let c
		if (!tip) {
			c = 'untipped'
		} else if (tip.aiTip) {
			c = 'aiTip'
		} else if (game.hscore && game.ascore && tip.hPts === game.hscore && tip.aPts === game.ascore) {
			c = 'actual-tip'
		} else {
			c = 'humantip'
		}
		return (
			<div
				key={game.id}
				className="fixture-row"
				onClick={this.props.onClick}
			>
				<div className="fixture-cell">
					{round}
				</div>
				<div
					className={"fixture-cell last-tip-box " + c}
					onClick={this.props.onClick}
				>
					<GameScore
						hteam={this.props.hteam}
						ateam={this.props.ateam}
						hscore={tip && tip.hPts}
						ascore={tip && tip.aPts}
						winner={tip && tip.winner}
					/>
				</div>
				<div className="fixture-cell">
					{game.venue}
				</div>
			</div>
		);
	}
}

FixtureRow.propTypes = {
	game: PropTypes.object,
	prevGameRound: PropTypes.number,
	onClick: PropTypes.func,
	hteam: PropTypes.object,
	ateam: PropTypes.object,
	tip: PropTypes.object,
};

const AllowTippingPastGames = props => {
	const { allowPastTips, setAllowPastTips, fixtureYear, setFixtureYear } = props

	const MIN_YEAR = 1897;
	const MAX_YEAR = new Date().getFullYear()

	const yearArr = [ ]
	for (let yr = MAX_YEAR; yr >= MIN_YEAR; yr -= 1) {
		if (yr === 2020) {
			yearArr.push([ '2020', '2020 (17 games)' ], [ '2020-original', '2020 (22 games)' ])
		} else {
			yearArr.push(yr)
		}

	}

	const defaultYear = fixtureYear || MAX_YEAR

	return (
		<div className="allow-tipping-past">
			<p>
				<label>
					<input
						type="checkbox"
						defaultChecked={allowPastTips}
						onChange={e => setAllowPastTips(e.target.checked)}
					/>
					Allow tipping of past games
				</label>
			</p>
			<p className={`allow-tipping-past-year allow-tipping-past-year-${allowPastTips ? 'en' : 'dis'}abled`}>
				Use fixture from
				{' '}
				<select
					className="sbox"
					defaultValue={defaultYear}
					disabled={!allowPastTips}
					onChange={e => setFixtureYear(e.target.value)}
				>
					{
						yearArr.map(yr => {
							const [ key, text ] = Array.isArray(yr) ? yr : [ yr, yr ]
							return (
								<option key={key} value={key}>
									{text}
								</option>
							)
						})
					}
				</select>
			</p>
		</div>
	)
}

AllowTippingPastGames.propTypes = {
	allowPastTips: PropTypes.bool,
	setAllowPastTips: PropTypes.func,
	fixtureYear: PropTypes.any,
	setFixtureYear: PropTypes.func,
}

export default Fixture;
